<template>
  <div>
    <draggable
      :list="activeStep"
      handle=".move"
    >
      <div
        v-for="(control, index) in fieldsList"
        :key="index"
      >
        <form-controls-list-item-v2
          :control="control"
          :index="index"
          :active="active"
          :config="confirmationStep ? confirmation : step"
          :section="section"
          :form="form"
          :single-product="isSingleProduct"
          :providers="providersList"
          @open-settings="active === index ? active = null : active = index"
          @deleted="deleteControl(control)"
        />
      </div>
    </draggable>
    
    <add-new-control
      v-if="!confirmationStep"
      :form-id="1"
      :items-count="fieldsList.length"
      @added-item="item => step.content.push(item)"
      @added-control="item => step.form.controls.push(item)"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import draggable from 'vuedraggable'
import FormControlsListItemV2 from '@/components/editor/components/form/FormControlsListItemV2.vue'
import AddNewControl from '@/components/editor/components/form/AddNewControl.vue'
import {mapState} from 'vuex'
export default {
  name: 'FormFieldV3',
  components: {
    AddNewControl,
    FormControlsListItemV2,
    draggable
  },
  props: {
    section: {
      type: Object,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    },
    confirmationStep: {
      type: Boolean,
      required: false
    },
    form: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      active: null
    }
  },
  computed: {
    ...mapState({
      providers: state => state.orderForm.providers
    }),
    products () {
      return this.form?.products || []
    },
    providersList () {
      const form = this.providers.find(item => item.id === this.form?.id)
      if (form) {
        return form.providers
      } else {
        return []
      }
    },
    isSingleProduct () {
      if (!this.products.length) return false
      const singleProduct = this.products.length === 1
      const hasPrices = this.products[0]?.prices?.length > 0
      return singleProduct && !hasPrices
    },
    step () {
      return this.section.options.content.orderForm.steps[this.currentStep]
    },
    confirmation () {
      return this.section.options.content.orderForm.confirmation[0]
    },
    activeStep () {
      if (this.confirmationStep) {
        return this.confirmation.content
      } else {
        return this.step.content
      }
    },
    fieldsList () {
      if (this.confirmationStep) {
        const formButtonRedirect = _.find(this.confirmation.content, ['class', 'form-button-redirect'])
        if (formButtonRedirect && !formButtonRedirect.fieldSettings) {
          formButtonRedirect.fieldSettings = true
        }
      }
      const stepContent = this.confirmationStep ? this.confirmation.content : this.step.content
      const content = _.chain(stepContent)
        .flatten()
        .filter({fieldSettings: true})
        .value()
      const flex = _.chain(stepContent)
        .filter({component: 'ElFlexWrapperV2'})
        .map('options.content.cols')
        .flatten()
        .map('elements')
        .flatten()
        .filter({fieldSettings: true})
        .value()
      
      return [
        ...content,
        ...flex
      ]
    }
  },
  methods: {
    deleteControl (val) {
      let item = _.findIndex(this.step.content, ['uid', val.uid])
      let control = _.findIndex(this.step.form.controls, ['uid', val.uid])

      if (item === -1) {
        const flexList = _.chain(this.step.content).filter({component: 'ElFlexWrapperV2'})
          .filter({component: 'ElFlexWrapperV2'})
          .flatten()
          .value()

        for (let i = 0; i < flexList.length; i++) {
          const flex = flexList[i]
          const cols = flex.options.content.cols

          for (let j = 0; j < cols.length; j++) {
            const col = cols[j]

            item = _.chain(col.elements)
              .findIndex({uid: val.uid})
              .value()

            if (item === -1) continue
            else return col.elements.splice(item, 1)
          }
        }
      }
      else this.step.content.splice(item, 1)
      this.step.form.controls.splice(control, 1)
    }
  }
}
</script>