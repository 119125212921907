<template>
  <div>
    <div class="ml-[6px]">
      <h5 class="font-semibold text-[25px] leading-[30px] text-black mb-[10px]">
        Payment Processing
      </h5>
      <p class="text-[16px] leading-[26px] text-[#6E747A] mb-[65px]">
        Collect payments on the Offers you sell in Estage. <br>
        We offer a variety of ways your members will be able to pay for your products.
      </p>

      <integration-item
        v-for="(item, index) in filteredItems"
        :key="index"
        :item="item"
        :class="{'mb-[16px]':index + 1 !== filteredItems.length }"
        @submit="val => handleSubmit(val,item)"
        @toggle-status="val => handleToggleStatus(val,item)"
        @remove="val => handleDelete(val,item)"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import IntegrationItem from '@/views/dashboard/integration/components/IntegrationItem.vue'
import IntegrationsMixin from '@/mixins/IntegrationsMixin'
export default {
  components: {IntegrationItem},
  mixins: [IntegrationsMixin],
  data () {
    return {
      items: [
        {
          integration_id: 10,
          title: 'Authorize.net',
          active: false,
          status: 'Dev',
          image: require('@/assets/images/integrations/authorize-net-image.svg'),
          icon: require('@/assets/images/integrations/authorize-net-icon.jpeg'),
          alpha_code: 'authorize_net',
          required_business_details: true,
          data: [
            {
              label: 'Client Key',
              value: '',
              type: 'text',
              key: 'public_key',
              dirty: false,
              validation: (val) => {
                return {
                  valid: val.length,
                  message: 'Field is required.'
                }
              }
            },
            {
              label: 'API Login ID',
              value: '',
              type: 'text',
              key: 'login_id',
              dirty: false,
              validation: (val) => {
                return {
                  valid: val.length,
                  message: 'Field is required.'
                }
              }
            },
            {
              label: 'Transaction Key',
              value: '',
              type: 'text',
              key: 'transaction_key',
              dirty: false,
              validation: (val) => {
                return {
                  valid: val.length,
                  message: 'Field is required.'
                }
              }
            }
          ]
        },
        {
          integration_id: 11,
          title: 'Easy Pay Direct',
          active: false,
          image: require('@/assets/images/integrations/easy-pay-direct-image.png'),
          icon: require('@/assets/images/integrations/easy-pay-direct-icon.webp'),
          video: 'https://player.estage.com/xqeOnAla3toud2Gd8XR1',
          alpha_code: 'easy_pay_direct',
          required_business_details: true,
          light_icon: true,
          integration_data: [
            {
              label: 'Your Webhook URL',
              type: 'text',
              value: 'https://api.estage.com/api/easypaydirect/webhook'
            }
          ],
          data: [
            {
              label: 'Private Security Key',
              value: '',
              type: 'text',
              key: 'signing_key',
              dirty: false,
              validation: (val) => {
                return {
                  valid: val.length,
                  message: 'Field is required.'
                }
              }
            },
            {
              label: 'Public Security Key',
              value: '',
              type: 'text',
              key: 'public_key',
              dirty: false,
              validation: (val) => {
                return {
                  valid: val.length,
                  message: 'Field is required.'
                }
              }
            },
            {
              label: 'Webhook Signing Key',
              value: '',
              type: 'text',
              key: 'signing_key',
              dirty: false,
              validation: (val) => {
                return {
                  valid: val.length,
                  message: 'Field is required.'
                }
              }
            }
          ]
        },
        {
          integration_id: 9,
          title: 'PayPal',
          active: false,
          image: require('@/assets/images/integrations/paypal-image.png'),
          icon: require('@/assets/images/integrations/paypal-icon.png'),
          video: 'https://player.estage.com/sv0s83XuEuqGl7cIBket',
          alpha_code: 'paypal',
          required_business_details: true,
          data: [
            {
              label: 'Client ID',
              value: '',
              type: 'text',
              key: 'public_key',
              dirty: false,
              validation: (val) => {
                return {
                  valid: val.length,
                  message: 'Field is required.'
                }
              }
            },
            {
              label: 'Secret Key',
              value: '',
              type: 'text',
              key: 'private_key',
              dirty: false,
              validation: (val) => {
                return {
                  valid: val.length,
                  message: 'Field is required.'
                }
              }
            }
          ]
        },
        {
          integration_id: 12,
          title: 'Razorpay',
          active: false,
          image: require('@/assets/images/integrations/razorpay-image.svg'),
          icon: require('@/assets/images/integrations/razorpay-icon.png'),
          video: 'https://player.estage.com/o5fPbeV2daRNFWwAYmbG',
          alpha_code: 'razorpay',
          required_business_details: true,
          integration_data: [
            {
              label: 'Your Webhook URL',
              type: 'text',
              value: 'https://api.estage.com/api/user/payment-systems/products/razorpay-webhook'
            }
          ],
          data: [
            {
              label: 'Key ID',
              value: '',
              type: 'text',
              key: 'public_key',
              dirty: false,
              validation: (val) => {
                return {
                  valid: val.length,
                  message: 'Field is required.'
                }
              }
            },
            {
              label: 'Secret Key',
              value: '',
              type: 'text',
              key: 'private_key',
              dirty: false,
              validation: (val) => {
                return {
                  valid: val.length,
                  message: 'Field is required.'
                }
              }
            }
          ]
        },
        {
          integration_id: 13,
          title: 'Square',
          active: false,
          image: require('@/assets/images/integrations/square-logotype.svg'),
          icon: require('@/assets/images/integrations/square-logo.svg'),
          video: 'https://player.estage.com/Z96gASNj5YNICHxF3Ikl',
          alpha_code: 'square',
          required_business_details: true,
          integration_data: [
            {
              label: 'Step 1 - Create your app:',
              type: 'link',
              value: 'https://developer.squareup.com/console/en/apps'
            }
          ],
          data: [
            {
              label: 'Application ID',
              value: '',
              type: 'text',
              key: 'public_key',
              dirty: false,
              validation: (val) => {
                return {
                  valid: val.length,
                  message: 'Field is required.'
                }
              }
            },
            {
              label: 'Access Token',
              value: '',
              type: 'text',
              key: 'private_key',
              dirty: false,
              validation: (val) => {
                return {
                  valid: val.length,
                  message: 'Field is required.'
                }
              }
            },
            {
              label: 'Location ID',
              value: '',
              type: 'text',
              key: 'location_id',
              dirty: false,
              validation: (val) => {
                return {
                  valid: val.length,
                  message: 'Field is required.'
                }
              }
            }
          ]
        },
        {
          integration_id: 8,
          title: 'Stripe',
          active: false,
          image: require('@/assets/images/integrations/stripe-image.png'),
          icon: require('@/assets/images/integrations/stripe-icon.jpeg'),
          alpha_code: 'stripe',
          data: [
            {
              label: 'Publishable key',
              value: '',
              type: 'text',
              key: 'public_key',
              dirty: false,
              validation: (val) => {
                return {
                  valid: val.length,
                  message: 'Field is required.'
                }
              }
            },
            {
              label: 'Secret key',
              value: '',
              type: 'text',
              key: 'private_key',
              dirty: false,
              validation: (val) => {
                return {
                  valid: val.length,
                  message: 'Field is required.'
                }
              }
            }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters('auth', {
      isSuperAdmin: 'isSuperAdmin',
      isSupport: 'isSupport'
    }),
    isDev () {
      return this.isSuperAdmin || this.isSupport
    },
    filteredItems () {
      return this.items.filter(item => {
        if (this.isDev) return true
        return item.status !== 'Dev'
      })
    }
  }
}
</script>