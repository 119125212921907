<template>
  <div
    v-if="showSummary"
    :data-sid="`product-summary-${sid}`"
    class="position-relative text-container"
    :class="`--${section.sid}--margin`"
  >
    <highliter
      v-model="section.name"
      :dublicate="section"
      class="highlight-element flex flex-col"
      section-type="element"
      :hide-right="true"
      @move-to="to => $emit('move-to', to)"
      @add-subject="$emit('add-subject')"
    >
      <div
        class="flex flex-col m-0"
        :class="`--${section.sid}--container`"
      >
        <h4
          class="payment-summary-title text-left m-0"
          :class="`--${section.sid}--title`"
        >
          Payment Summary
        </h4>
      
        <div
          class="flex flex-row items-center justify-between"
          :class="`--${section.sid}--item`"
        >
          <p
            class="payment-summary-product text-left m-0"
            :class="`--${section.sid}--product`"
          >
            {{ product.name }}
          </p>
          <span
            class="payment-summary-price text-right m-0 flex items-center"
            :class="`--${sid}--price`"
          >
            {{ product.trial ? `${product.trial} Day(s) FREE, then` : null }}
            <span
              v-if="product.price === '0.00' || product.cost === '0.00'"
              class="ml-1"
            >
              Free
            </span>
            <Price
              v-else
              :amount="Number(product.price || product.cost)"
              :currency-code="product.currency || 'USD'"
              class="ml-1"
            />
            {{ product.interval && product.interval !== 'one_time' ? `/${product.interval.substring(0, 2)}.` : null }}
          </span>
        </div>
      </div>
    </highliter>
  </div>
</template>

<script>
import BorderMixin from '@/components/mixins/BorderMixin'
import SectionMixin from '@/components/mixins/SectionMixin'
import Highliter from '@/components/builder/utils/Highliter.vue'
import TextMixins from '@/components/mixins/TextMixins'
import Price from '@/components/utils/Price.vue'
export default {
  name: 'ElPaymentSummary',
  mixins: [BorderMixin, SectionMixin, TextMixins],
  components: {Price, Highliter},
  props: {
    form: {
      type: Object,
      required: false
    }
  },
  computed: {
    products () {
      return this.form?.products || []
    },
    isSingleProduct () {
      if (!this.products.length) return false
      const singleProduct = this.products.length === 1
      const hasPrices = this.products[0]?.prices?.length > 0
      return singleProduct && !hasPrices
    },
    showSummary () {
      return this.isSingleProduct
    },
    product () {
      if (!this.products.length || !this.products[0]) {
        return {
          name: ''
        }
      }
      return this.products[0]
    }
  }
}
</script>